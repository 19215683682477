<template>
  <div class="dayregister_container">
    <el-row class="dayregister_header">
      <el-col :span="5">{{ name }}</el-col>
      <el-col :offset="4" :span="15" class="step"><span class="active">1.选择科室</span>&nbsp;&nbsp;2.选择医生&nbsp;&nbsp;3.选择时段&nbsp;&nbsp;4.确认挂号 </el-col>
    </el-row>
    <Count></Count>

    <div class="dayregister_main">
      <el-row class="dayregister_title">
        <div class="block" v-if="name === '预约挂号'">
          <span class="title-item">选择日期：</span>
          <el-select v-model="value" placeholder="请选择" class="sele" size="medium">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="title-item">
          当前位置：科室列表
        </div>
        <div class="title-item">
          姓名：<span>{{ patName }}</span>

          就诊卡号：<span>{{ patCardNo }}</span>
        </div>
      </el-row>
      <div class="itemBox">
        <div v-for="(item, i) in deptList" :key="i" class="item" @click="list(item)">
          <div class="content">{{ item.KSMC || item.MC }}</div>
        </div>
      </div>
    </div>
    <el-row class="dayregister_tip"></el-row>
    <el-button class="back" type="primary" @click.native="back">返回</el-button>
    <el-button class="home" type="primary" @click.native="home">首页</el-button>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'
import Count from '../count/index'
import dayjs from 'dayjs'
import { getRegListToday, getAppointmentDept } from '@/service/register'
import qs from 'qs'
import { isArray } from 'util'
export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      name: '',
      patName: '',
      patCardNo: '',
      deptList: [],
      fullscreenLoading: false,
      noticeText: '',
      noticeTitle: '',
      notice: true,
      buttonText: '我知道了',
      disabled: false,
      timer: null,
      countDown: 15,
      options: [
        {
          value: '选项1',
          label: ''
        },
        {
          value: '选项2',
          label: ''
        },
        {
          value: '选项3',
          label: ''
        },
        {
          value: '选项4',
          label: ''
        },
        {
          value: '选项5',
          label: ''
        },
        {
          value: '选项6',
          label: ''
        },
        {
          value: '选项7',
          label: ''
        }
      ],
      value: '',
      jsonDay: [],
      timeSlot: ''
    }
  },
  watch: {
    value(newVal, old) {
      let obj = this.options.find(i => i.value === newVal)
      this.timeSlot = obj.label
      this.getDeptList()
      this.value = this.timeSlot
    }
  },
  created() {
    this.name = this.$route.query.name
    this.patName = this.$route.query.patName
    this.patCardNo = this.$route.query.patCardNo
    this.openFullScreen()
    this.getDay()
    this.getDeptList()
    console.log(this.options)
  },
  methods: {
    //获取今天日期
    getToday() {
      var date = new Date()
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      var currentdate = year + '-' + month + '-' + strDate
      this.timeSlot = currentdate
    },
    // 获取明天日期
    getTomorrow() {
      var date = new Date()
      date.setDate(date.getDate() + 1)
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      var currentdate = year + '-' + month + '-' + strDate
      this.value = currentdate
    },
    //获取一个星期的日期
    getDay() {
      var arr = []
      var day = []
      var week = []
      const options = this.options
      for (var i = 0; i <= 6; i++) {
        day.push(this.formatTime(i))
        week.push(this.formatWeek(this.getWeek(i)))
      }
      for (var i = 0; i < day.length; i++) {
        var str = day[i]
        var s = str.split('-')
        var json = {}
        for (var j = 0; j < s.length; j++) {
          json.year = s[0]
          json.month = s[1]
          json.day = s[2]
        }
        arr.push(json)
      }
      for (var i = 0; i < week.length; i++) {
        arr[i].week = week[i]
      }
      for (var i = 0; i < arr.length; i++) {
        arr[i].checked = false
      }
      for (var j = 0; j < options.length; j++) {
        options[j].label = day[j]
        options[j].value = day[j]
      }
      this.options = options
      arr[0].checked = true
      this.jsonDay = arr
    },
    padStartConvert(n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    },

    formatTime(num) {
      var now = new Date()
      var nowTime = now.getTime()
      var oneDayTime = 24 * 60 * 60 * 1000
      var ShowTime = nowTime + num * oneDayTime
      var myDate = new Date(ShowTime)
      var y = myDate.getFullYear()
      var m = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1
      var d = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()
      return [y, m, d].map(this.padStartConvert).join('-')
    },

    //获取周期
    getWeek(num) {
      var now = new Date()
      var nowTime = now.getTime()
      var oneDayTime = 24 * 60 * 60 * 1000
      var ShowTime = nowTime + num * oneDayTime
      var myDate = new Date(ShowTime)
      var y = myDate.getFullYear() //年
      var m = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1 //月
      var d = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate() //日
      return [y, m, d].map(this.padStartConvert).join('-')
    },
    //格式化周期
    formatWeek(date) {
      var weekArray = new Array('日', '一', '二', '三', '四', '五', '六')
      var week = weekArray[new Date(date).getDay()]
      return week
    },
    back() {
      this.$router.back()
    },
    openFullScreen() {
      this.fullscreenLoading = true
      setTimeout(() => {
        this.fullscreenLoading = false
      }, 2000)
    },
    home() {
      this.$router.push('/home')
    },
    list(item) {
      this.$router.push({
        path: '/list',
        query: {
          name: this.name,
          patName: this.patName,
          KSBM: item.KSBM,
          BM: item.BM,
          cardNo: this.$route.query.cardNo,
          patCardNo: this.patCardNo,
          patCardType: this.$route.query.patCardType
        }
      })
    },
    // 获取科室列表
    async getDeptList() {
      if (this.name == '当天挂号') {
        const res = await getRegListToday()
        console.log(res)
        if (res.data.code == 0) {
          if (Array.isArray(res.data.data.DATASET.ROW)) {
            this.deptList = res.data.data.DATASET.ROW
          } else {
            let item = []
            item.push(res.data.data.DATASET.ROW)
            this.deptList = item
          }
        } else {
          this.deptList = []
        }
      } else {
        let data = {
          KSRQ: dayjs(new Date()).format('YYYY-MM-DD'),
          JSRQ: this.value
        }
        const res = await getAppointmentDept(data)
        console.log(res)
        if (res.data.code == 0) {
          console.log(res)

          if (Array.isArray(res.data.data.DATASET.ROW)) {
            this.deptList = res.data.data.DATASET.ROW
          } else {
            let item = []
            item.push(res.data.data.DATASET.ROW)
            this.deptList = item
          }
        } else {
          this.deptList = []
        }
      }
    },
    startTimer() {
      this.disabled = true
      this.buttonText = `${this.countDown}秒后可点击`
      this.timer = setInterval(() => {
        this.countDown--
        this.buttonText = `${this.countDown}秒后可点击`
        if (this.countDown === 0) {
          clearInterval(this.timer)
          this.disabled = false
          this.buttonText = '我知道了'
          this.countDown = 60
        }
      }, 1000)
    },
    ok() {
      this.notice = false
    }
  }
}
</script>
<style lang="less" scoped>
.dayregister_container {
  width: 100%;
  height: 100%;
  //background-image: url('../../assets/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.dayregister_container .mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.3;
  z-index: 1;
}

.dayregister_container .popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #dcdfe6;
  width: 70%;
  height: 60%;
  border-radius: 10px;
  padding: 20px 30px;
  z-index: 999;
}

.dayregister_container .popup h1 {
  text-align: center;
  letter-spacing: 10px;
  font-size: 50px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.dayregister_container .popup .noticeText {
  line-height: 55px;
}

.dayregister_container .popup .el-button {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 38px !important;
}

.dayregister_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.itemBox {
  padding: 100px;
  margin-bottom: 50px;
}

.line {
  height: 100px;
}

.dayregister_header .step {
  font-size: 35px;
  font-weight: 400;
  padding: 0;
  letter-spacing: 0;
  text-align: right;
  padding-right: 30px;
}

.dayregister_header .step .active {
  color: blue;
}

.dayregister_title {
  background-color: #409eff;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dayregister_title .title-item {
  color: #fff;
  font-size: 42px;
  font-weight: bold;
}

.dayregister_title .title-item span {
  font-weight: 700;
}
.list_title .block {
  height: 100px;
  position: absolute;
  top: 200px;
  right: 100px;
}

.dayregister_main {
  max-height: 60%;
  overflow: auto;
  margin-top: 7%;
  background-color: #fff;
}

.dayregister_main .item {
  display: inline-block;
  width: 28%;
  height: 140px;
  line-height: 140px;
  align-items: center;
  color: white;
  font-size: 42px;
  background-color: #409eff;
  border-radius: 6px;
  margin: 10px 28px;
  text-align: -webkit-center;
}

.dayregister_main .item .content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-weight: 700;
}

.dayregister_tip {
  position: fixed !important;
  bottom: 40px;
  left: 0;
  z-index: 1;
  padding-left: 30px;
  color: red;
  font-size: 20px;
}

.dayregister_tip span {
  color: blue;
}

.dayregister_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.back {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 270px;
  font-size: 35px !important;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 35px !important;
}
</style>
